import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'STAR-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x79ff4c9589d402ccb5d5b8a63282216a1749498e',
    },
    tokenSymbol: 'STAR',
    tokenAddresses: {
      97: '',
      56: '0x9B06fbbdeDab2cbd48216cAB10eA315479764Baf',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'STAR-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xcb45f6920251603fce85ffed710a0a9975f6fc33',
    },
    tokenSymbol: 'STAR',
    tokenAddresses: {
      97: '',
      56: '0x9B06fbbdeDab2cbd48216cAB10eA315479764Baf',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 14,
    risk: 5,
    isTokenOnly: true,
    computeAsLP: true,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'GO12-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x9f76fb77eae25a522264bf013ca862e6cfb3b106',
    },
    tokenSymbol: 'GO12',
    tokenAddresses: {
      97: '',
      56: '0x86ae62e5dcc20b070d1059315ef48a7ec8a7aa8c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 5,
    lpSymbol: 'AQ12-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x9BFd240CF183F07DFe7696e4602A2431ff826121',
    },
    tokenSymbol: 'AQ12',
    tokenAddresses: {
      97: '',
      56: '0x412c00e23b924d73f6c79ef0754e56b8c4394789',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 4,
    risk: 5,
    lpSymbol: 'PI12-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x336351f9A34FC448268aE1130A8a292e73f48343',
    },
    tokenSymbol: 'PI12',
    tokenAddresses: {
      97: '',
      56: '0x0695e0af861d531af9e0439a691bdb602a5e81fb',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'AR12-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x07a760e72C270108FaDBde8D643cdeBeDE01F2C6',
    },
    tokenSymbol: 'AR12',
    tokenAddresses: {
      97: '',
      56: '0x47cef3090e61fd87faca201c0a8941be552cdfcb',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'TA12-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x8d70BD47a90B81a53A45d5248B8e5B4882b990FD',
    },
    tokenSymbol: 'TA12',
    tokenAddresses: {
      97: '',
      56: '0xe574f4f37e8af6cfcf555db632a6eb8f237aa738',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'GE12-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x701eEe76459c6F29ACCb364BcC2a02b6B86d78fC',
    },
    tokenSymbol: 'GE12',
    tokenAddresses: {
      97: '',
      56: '0x3f6b5b5c8b20ce5a86af448c3e2f973c1dbaf8e9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'CA12-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x7e65A94926fC46Bf97281D42c590308b5fEF8504',
    },
    tokenSymbol: 'CA12',
    tokenAddresses: {
      97: '',
      56: '0x22bf1b6cd38851f565935a844382c5fac9e3e2ab',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 9,
    risk: 5,
    lpSymbol: 'LE12-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xa407b4e3C9175c2Be1747037819A6C0BDcAe4b67',
    },
    tokenSymbol: 'LE12',
    tokenAddresses: {
      97: '',
      56: '0xf024fb3ae16e5b1cc96e421acecd884744e3fbab',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  // {
  //   pid: 10,
  //   risk: 5,
  //   lpSymbol: 'LE12-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa407b4e3C9175c2Be1747037819A6C0BDcAe4b67',
  //   },
  //   tokenSymbol: 'LE12',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf024fb3ae16e5b1cc96e421acecd884744e3fbab',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  // {
  //   pid: 15,
  //   risk: 5,
  //   lpSymbol: 'VI12-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x2b9d1ea4d7f1cff222c5094ce63b1645def60538',
  //   },
  //   tokenSymbol: 'VI12',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xb7c0d347c6c6e6544da6a8b4c32d248adc9c0ede',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
  {
    pid: 16,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 17,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      97: '',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 18,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      97: '',
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      97: '',
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 19,
    risk: 5,
    lpSymbol: 'VI12-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xb25B539C41C362bC6A7498c6e9D4Dff556FCf213',
    },
    tokenSymbol: 'VI12',
    tokenAddresses: {
      97: '',
      56: '0x2b9d1ea4d7f1cff222c5094ce63b1645def60538',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    risk: 5,
    lpSymbol: 'LI12-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x372c80F4B4528d9B6e500a19663C6CC1805bE940',
    },
    tokenSymbol: 'LI12',
    tokenAddresses: {
      97: '',
      56: '0xb67e8bc429e4526863fe22544bf215cc022565ec',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    risk: 5,
    lpSymbol: 'SC12-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x928A3f2CB37BBA9B3fEDf90740BF4F705082d839',
    },
    tokenSymbol: 'SC12',
    tokenAddresses: {
      97: '',
      56: '0x4ade73501988259c2d97767cac5533274de2d0a3',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'SA12-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xB7c0d347C6c6E6544Da6A8B4c32D248Adc9C0EDe',
    },
    tokenSymbol: 'SA12',
    tokenAddresses: {
      97: '',
      56: '0x93e009c252564fca4e6177ad4d046744765fbcfd',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 21,
    risk: 5,
    isTokenOnly: true,
    computeAsLP: true,
    lpSymbol: 'USDT-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x85f8628bfff75d08f1aa415e5c7e85d96bfd7f57',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 20,
    risk: 5,
    isTokenOnly: true,
    computeAsLP: true,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 22,
    risk: 5,
    isTokenOnly: true,
    computeAsLP: true,
    lpSymbol: 'USDC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x680dd100e4b394bda26a59dd5c119a391e747d18',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 23,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'CEEK VR',
    lpAddresses: {
      97: '',
      56: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
    },
    tokenSymbol: 'CEEK VR',
    tokenAddresses: {
      97: '',
      56: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 24,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'CEEK-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x02E6D8Ffd0eB3040DA41bbE423dD5EF5b599832a',
    },
    tokenSymbol: 'CEEK',
    tokenAddresses: {
      97: '',
      56: '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 25,
    risk: 5,
    isPlanet12: true,
    isTokenOnly: true,
    lpSymbol: 'STAR Token',
    lpAddresses: {
      97: '',
      56: '0x9B06fbbdeDab2cbd48216cAB10eA315479764Baf',
    },
    tokenSymbol: 'STAR',
    tokenAddresses: {
      97: '',
      56: '0x9B06fbbdeDab2cbd48216cAB10eA315479764Baf',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 26,
    risk: 5,
    isTokenOnly: true,
    computeAsLP: true,
    lpSymbol: 'USDC-STAR LP',
    lpAddresses: {
      97: '',
      56: '0xd5024bFb674C583DFF16ffb98904Cf1Cb97B44c7',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      97: '',
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 27,
    risk: 5,
    isTokenOnly: true,
    computeAsLP: true,
    lpSymbol: 'USDT–STAR LP',
    lpAddresses: {
      97: '',
      56: '0xf98fe9CF1D43cf44D252D53719Ae88Bda8738Bf3',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      97: '',
      56: '0x55d398326f99059ff775485246999027b3197955',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 28,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'RACA Token',
    lpAddresses: {
      97: '',
      56: '0x12BB890508c125661E03b09EC06E404bc9289040',
    },
    tokenSymbol: 'RACA',
    tokenAddresses: {
      97: '',
      56: '0x12BB890508c125661E03b09EC06E404bc9289040',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 29,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'RACA–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xc2fca3E2ddbb968AD33887E317436cf2D2e88c6C',
    },
    tokenSymbol: 'RACA',
    tokenAddresses: {
      97: '',
      56: '0x12BB890508c125661E03b09EC06E404bc9289040',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 30,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'MBox Token',
    lpAddresses: {
      97: '',
      56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
    },
    tokenSymbol: 'MBox',
    tokenAddresses: {
      97: '',
      56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 31,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'MBox–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x630011a2BF17C27D7a9F9fDcc2e5D7641ef3351F',
    },
    tokenSymbol: 'MBox',
    tokenAddresses: {
      97: '',
      56: '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 32,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'DERC Token',
    lpAddresses: {
      97: '',
      56: '0x373E768f79c820aA441540d254dCA6d045c6d25b',
    },
    tokenSymbol: 'DERC',
    tokenAddresses: {
      97: '',
      56: '0x373E768f79c820aA441540d254dCA6d045c6d25b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 33,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'DERC–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x130927D1B84DfE0E5D705FE939D0788E4A76e993',
    },
    tokenSymbol: 'DERC',
    tokenAddresses: {
      97: '',
      56: '0x373E768f79c820aA441540d254dCA6d045c6d25b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 34,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'Gala Token',
    lpAddresses: {
      97: '',
      56: '0x7ddee176f665cd201f93eede625770e2fd911990',
    },
    tokenSymbol: 'Gala',
    tokenAddresses: {
      97: '',
      56: '0x7ddee176f665cd201f93eede625770e2fd911990',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 35,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'Gala–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x851f9BB052fC465355B274cEE66F5A25f7cc4F6B',
    },
    tokenSymbol: 'Gala',
    tokenAddresses: {
      97: '',
      56: '0x7ddee176f665cd201f93eede625770e2fd911990',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 36,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'DAR Token',
    lpAddresses: {
      97: '',
      56: '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
    },
    tokenSymbol: 'DAR',
    tokenAddresses: {
      97: '',
      56: '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 37,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'DAR–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x53228618B564f8d497d14d1d1b5D3Ad04907f6a6',
    },
    tokenSymbol: 'DAR',
    tokenAddresses: {
      97: '',
      56: '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 38,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'GAFI Token',
    lpAddresses: {
      97: '',
      56: '0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e',
    },
    tokenSymbol: 'GAFI',
    tokenAddresses: {
      97: '',
      56: '0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 39,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'GAFI–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x8Ae8FBfac57dfaF56b556d33e45Fc5B1984d2876',
    },
    tokenSymbol: 'GAFI',
    tokenAddresses: {
      97: '',
      56: '0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 40,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'Alice Token',
    lpAddresses: {
      97: '',
      56: '0xac51066d7bec65dc4589368da368b212745d63e8',
    },
    tokenSymbol: 'Alice',
    tokenAddresses: {
      97: '',
      56: '0xac51066d7bec65dc4589368da368b212745d63e8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 41,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'Alice–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x9a152dF3D47aD2a5ef88480e0078610D92681F71',
    },
    tokenSymbol: 'Alice',
    tokenAddresses: {
      97: '',
      56: '0xac51066d7bec65dc4589368da368b212745d63e8',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 42,
    risk: 5,
    isMetaOnly: true,
    lpSymbol: 'DVI Token',
    lpAddresses: {
      97: '',
      56: '0x758fb037a375f17c7e195cc634d77da4f554255b',
    },
    tokenSymbol: 'DVI',
    tokenAddresses: {
      97: '',
      56: '0x758fb037a375f17c7e195cc634d77da4f554255b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 43,
    risk: 5,
    isMetaOnly: true,
    computeAsLP: true,
    lpSymbol: 'DVI–BUSD LP',
    lpAddresses: {
      97: '',
      56: '0xe19b7DE4ACbC404f804FE2c3Ff6d30601fbdA0e5',
    },
    tokenSymbol: 'DVI',
    tokenAddresses: {
      97: '',
      56: '0x758fb037a375f17c7e195cc634d77da4f554255b',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
]

export default farms
