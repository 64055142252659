export default {
  cake: {
    56: '0x9B06fbbdeDab2cbd48216cAB10eA315479764Baf',
    97: '',
  },
  masterChef: {
    56: '0xe79057F72EC244f6A0e3F8A66F7DAf5C155d2a9A',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  usdc: {
    56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    97: '',
  },
  usdt: {
    56: '0x55d398326f99059fF775485246999027B3197955',
    97: '',
  },
}
