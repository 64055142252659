import { useWallet } from '@binance-chain/bsc-use-wallet'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useTheme from 'hooks/useTheme'
import React, { useContext } from 'react'
import { usePriceCakeBusd, usePriceFarm } from 'state/hooks'
import { Menu as UikitMenu } from '../../uikit'
import config from './config'

const Menu = (props) => {
  const { account, connect, reset } = useWallet()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const prices = [
    {
      price: usePriceFarm(2),
      icon: '/images/farms/go12-busd.png',
    },
    {
      price: usePriceFarm(3),
      icon: '/images/farms/aq12-busd.png',
    },
    {
      price: usePriceFarm(4),
      icon: '/images/farms/pi12-busd.png',
    },
    {
      price: usePriceFarm(5),
      icon: '/images/farms/ar12-busd.png',
    },
    {
      price: usePriceFarm(6),
      icon: '/images/farms/ta12-busd.png',
    },
    {
      price: usePriceFarm(7),
      icon: '/images/farms/ge12-busd.png',
    },
    {
      price: usePriceFarm(8),
      icon: '/images/farms/ca12-bnb.png',
    },
    {
      price: usePriceFarm(9),
      icon: '/images/farms/le12-bnb.png',
    },
    {
      price: usePriceFarm(19),
      icon: '/images/farms/vi12-bnb.png',
    },
    {
      price: usePriceFarm(11),
      icon: '/images/farms/li12-bnb.png',
    },
    {
      price: usePriceFarm(12),
      icon: '/images/farms/sc12-bnb.png',
    },
    {
      price: usePriceFarm(13),
      icon: '/images/farms/sa12-bnb.png',
    },
  ]

  return (
    <UikitMenu
      account={account}
      login={connect}
      logout={reset}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={selectedLanguage && selectedLanguage.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={cakePriceUsd.toNumber()}
      links={config}
      prices={prices}
      {...props}
    />
  )
}

export default Menu
